<!-- 登录 -->
<template>
    <div class="login">
        <el-row>
            <el-col :span="24">
                <div class="top">
                    中国美术学院社会美术水平考试：机构报名管理系统
                </div>
            </el-col>
            <el-col :span="24">
                <div class="content">
                    <el-form ref="form" :model="form" :rules="rules" class="form">
                        <div class="jigou">
                            机构登录
                        </div>
                        <el-form-item label="" prop="userName">
                            <el-input v-model="form.userName" placeholder="请输入用户名"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="userPwd">
                            <el-input type="password" v-model="form.userPwd" placeholder="请输入密码" show-password
                                @keyup.enter.native="login('form')">
                            </el-input>
                        </el-form-item>
                        <div class="logReg">
                            <div class="">
                                <el-checkbox v-model="form.checked">记住账号</el-checkbox>
                            </div>
                            <div class="" style="color: #474747;cursor: pointer;" @click="regist">
                                注册账号
                            </div>
                        </div>
                        <el-form-item label="">
                            <button type="button" class="login-btn" @click="login('form')">登 录</button>
                        </el-form-item>
                        <p class="copy">
                            <span style="margin-right: 30px;cursor: pointer;" @click="copy">{{ copyright }}</span>
                            <span>All rights reserved</span>
                        </p>
                    </el-form>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios'
import Base64 from '../../utils/Base64.js'
export default {
    data() {
        return {
            form: {
                userName: "", //用户名
                userPwd: "", //密码
                checked: false, //记住用户名多选框
            },
            rules: {
                userName: [{
                    required: true,
                    message: '用户名不能为空',
                    trigger: 'blur'
                }],
                userPwd: [{
                    required: true,
                    message: '密码不能为空',
                    trigger: 'blur'
                }],
            },
            bool: false,
            copyright: ""
        }
    },
    created() {
        // 解密账号
        let username = this.$cookies.get("userName")
        // console.log(username, userpwd)
        if (username) {
            this.form.userName = Base64.decode(username)
            this.form.checked = true
        }

        // 发送一个 POST 请求
        axios({
            method: 'POST',
            url: 'https://www.szart.cn/api/public/index.php/api/common/webinfo',
            data: {}
        }).then(res => {
            console.log(res)
            let data = res.data.data
            this.copyright = data.copyright + data.beian//网站版权所有
        });
    },
    methods: {
        login(formName) { //登录
            // console.log(111)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$uitls.delay(300).then(() => {
                        this.$request({
                            url: '/api/mechanismexam/login',
                            method: 'POST',
                            data: {
                                username: this.form.userName,
                                password: this.form.userPwd
                            }
                        }).then(res => {
                            // console.log(res,"登录信息")
                            if (res.code == 1) {
                                this.$message({
                                    message: '登录成功',
                                    type: 'success'
                                })
                                localStorage.setItem('token', res.data.id)
                                localStorage.setItem("get_info", JSON.stringify(res.data));
                                this.setUserInfo()
                                this.$router.replace({
                                    path: "/dashboard"
                                })
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                            }
                        }).catch(() => {
                            this.bool = false
                        })
                    })

                    // if(this.bool){
                    //     return
                    // }
                    // this.bool = true
                    // setTimeout(()=>{
                    //     this.bool=false
                    // },1500)
                    // // alert('submit!');
                    // this.$request({
                    //     url: '/api/mechanismexam/login',
                    //     method: 'POST',
                    //     data: {
                    //         username: this.form.userName,
                    //         password: this.form.userPwd
                    //     }
                    // }).then(res=>{
                    //     // console.log(res,"登录信息")
                    //     if(res.code==1){
                    //        this.$message({
                    //            message: '登录成功',
                    //            type: 'success'
                    //        })
                    //        return
                    //        localStorage.setItem('token', res.data.id)
                    //        localStorage.setItem("get_info", JSON.stringify(res.data));
                    //        this.setUserInfo()
                    //        this.$router.replace({
                    //            path: "/dashboard"
                    //        })
                    //     }else{
                    //         this.$message({
                    //             message: res.msg,
                    //             type: 'error'
                    //         })
                    //     }
                    // }).catch(()=>{
                    //     this.bool=false
                    // })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        setUserInfo() {
            if (this.form.checked) {
                this.$cookies.set("userName", Base64.encode(this.form.userName))
                this.$cookies.set("checked", this.form.checked)
            } else {
                this.$cookies.remove('userName')
                this.$cookies.remove('checked')
            }
        },
        regist() { //注册
            this.$router.push({
                path: "/register",
            })
        },
        copy() {//版权所有
            window.open('https://beian.miit.gov.cn/')
        }
    }
}
</script>

<style scoped="scoped">
@media screen and (min-width:320px) {
    .top {
        font-size: 13px !important;
        height: 30px !important;
        line-height: 30px !important;
    }

    .jigou {
        font-size: 13px !important;
        height: 30px !important;
        line-height: 30px !important;
    }

    .form {
        width: 320px !important;
    }

    .el-input,
    .login-btn,
    .logReg,
    .copy {
        width: 240px !important;
    }

    .login-btn {
        font-size: 13px !important;
    }
}

@media screen and (min-width:375px) {
    .top {
        font-size: 15px !important;
        height: 40px !important;
        line-height: 40px !important;
    }

    .jigou {
        font-size: 15px !important;
        height: 40px !important;
        line-height: 40px !important;
    }

    .form {
        width: 350px !important;
    }

    .el-input,
    .login-btn,
    .logReg,
    .copy {
        width: 300px !important;
    }

    .login-btn {
        font-size: 15px !important;
    }
}

@media screen and (min-width:768px) {
    .top {
        font-size: 18px !important;
        height: 50px !important;
        line-height: 50px !important;
    }

    .jigou {
        font-size: 18px !important;
        height: 50px !important;
        line-height: 50px !important;
    }

    .form {
        width: 500px !important;
        box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16) !important;
    }

    .el-input,
    .login-btn,
    .logReg,
    .copy {
        width: 370px !important;
    }

    .login-btn {
        font-size: 18px !important;
    }
}

@media screen and (min-width:1024px) {
    .top {
        font-size: 28px !important;
        height: 70px !important;
        line-height: 70px !important;
    }

    .jigou {
        font-size: 24px !important;
        height: 60px !important;
        line-height: 60px !important;
    }

    .form {
        width: 700px !important;
        box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16) !important;
    }

    .el-input,
    .login-btn,
    .logReg,
    .copy {
        width: 469px !important;
    }

    .login-btn {
        font-size: 20px !important;
    }
}

.login {
    width: 100vw;
    height: 100vh;
}

.top {
    width: 100%;
    height: 70px;
    text-align: center;
    line-height: 70px;
    font-size: 30px;
    background: #29726C;
    color: #fff;
}

.content {
    width: 100vw;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content .form {
    width: 700px;
    height: 460px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.jigou {
    width: 100%;
    height: 40px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    line-height: 60px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.el-input {
    width: 469px;
    height: 55px;
}


.logReg {
    display: flex;
    justify-content: space-between;
    width: 469px;
    font-size: 13px;
}

.el-checkbox {
    color: #333;
}

::v-deep .el-checkbox__inner:hover {
    border-color: #ccc;

}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #333;
    font-size: 14px;
}

::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #ccc;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    color: #333;
    background-color: #29726C;
    border-color: #ccc;
}

/* 登录按钮 */
.login-btn {
    display: inline-block;
    width: 469px;
    height: 40px;
    background: #328A82;
    color: #fff;
    font-size: 20px;
    border: 1px solid #ccc;
    cursor: pointer;
    /* margin: 25px 0px; */
    margin-top: 50px;
}

.login-btn:hover {
    background-color: #29726C;
}

.copy {
    width: 469px;
    text-align: left;
    margin-top: 15px;
    font-size: 12px;
}
</style>
